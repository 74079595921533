define("widget/templates/job/match/report/summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "LdAjAJbt",
    "block": "[[[8,[39,0],null,[[\"@match\",\"@job\",\"@applyUrl\",\"@emailAddress\",\"@onApply\"],[[30,1],[30,0,[\"job\"]],[30,0,[\"applyUrl\"]],[30,0,[\"candidateEmail\"]],[30,0,[\"emitPartnerEvent\"]]]],null],[1,\"\\n\"]],[\"@model\"],false,[\"match/summary\"]]",
    "moduleName": "widget/templates/job/match/report/summary.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});