define("widget/templates/job/match/error-email-conflict", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "QTVUpdDk",
    "block": "[[[10,0],[12],[1,\"\\n  \"],[10,\"img\"],[14,0,\"m-auto\"],[14,\"src\",\"/assets/images/email.png\"],[14,\"role\",\"presentation\"],[14,\"alt\",\"\"],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"text-center\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"text-base font-semibold\"],[12],[1,\"Welcome back!\"],[13],[1,\"\\n  \"],[10,0],[14,0,\"text-xs text-center text-neutral-900 pt-2\"],[12],[1,\"\\n    We recognized your email. To continue and get your match feedback, confirm your email. We sent a verification link to your email from this resume.\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"mt-8\"],[12],[1,\"\\n\"],[1,\"  \"],[11,\"button\"],[24,0,\"w-full bg-blue-500 text-white text-semibold text-sm rounded py-4 mb-4 disabled:opacity-50\"],[16,5,[29,[\"background-color: \",[28,[37,0],[\"primaryColor\"],null],\";\"]]],[24,4,\"button\"],[4,[38,1],[\"click\",[30,0,[\"sendEmailAgain\"]]],null],[12],[1,\"\\n    Send Email Again\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"text-center py-4 font-semibold rounded py-4 text-sm mb-2\"],[12],[1,\"\\n\"],[1,\"  \"],[8,[39,2],[[16,5,[29,[\"color: \",[28,[37,0],[\"primaryColor\"],null],\";\"]]]],[[\"@route\",\"@model\"],[\"job.match.upload\",[30,1]]],[[\"default\"],[[[[1,\"\\n    Cancel\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[\"@model\"],false,[\"theme\",\"on\",\"link-to\"]]",
    "moduleName": "widget/templates/job/match/error-email-conflict.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});