define("widget/components/drawer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div data-test-drawer class="fixed bg-white bottom-0 top-3 left-3 right-3 rounded-t shadow-lg flex flex-col">
    <div class="flex pt-3 pl-3">
      <button
        data-test-drawer-close
        {{on 'click' @onClose}}
        type="button"
        class="cursor-pointer flex text-blue-500 flex items-center"
      >
        {{svg-jar "drawer-back" class="inline"}}
        <span class="pl-4 text-base font-semibold">Back</span>
      </button>
    </div>
    <div class="absolute cursor-pointer top-3 right-3">
      <button type="button" {{on 'click' @onClose}}>
        {{svg-jar "close-button-grey" class="inline"}}
      </button>
    </div>
    {{yield}}
  </div>
  */
  {
    "id": "T+PJj+/x",
    "block": "[[[10,0],[14,0,\"fixed bg-white bottom-0 top-3 left-3 right-3 rounded-t shadow-lg flex flex-col\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"flex pt-3 pl-3\"],[12],[1,\"\\n    \"],[11,\"button\"],[24,0,\"cursor-pointer flex text-blue-500 flex items-center\"],[24,4,\"button\"],[4,[38,0],[\"click\",[30,1]],null],[12],[1,\"\\n      \"],[1,[28,[35,1],[\"drawer-back\"],[[\"class\"],[\"inline\"]]]],[1,\"\\n      \"],[10,1],[14,0,\"pl-4 text-base font-semibold\"],[12],[1,\"Back\"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"absolute cursor-pointer top-3 right-3\"],[12],[1,\"\\n    \"],[11,\"button\"],[24,4,\"button\"],[4,[38,0],[\"click\",[30,1]],null],[12],[1,\"\\n      \"],[1,[28,[35,1],[\"close-button-grey\"],[[\"class\"],[\"inline\"]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[18,2,null],[1,\"\\n\"],[13]],[\"@onClose\",\"&default\"],false,[\"on\",\"svg-jar\",\"yield\"]]",
    "moduleName": "widget/components/drawer.hbs",
    "isStrictMode": false
  });
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
  _exports.default = _default;
});