define("widget/components/match/fit-title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="text-sm text-neutral-900">
    {{#if (eq @qualification 'BAD')}}
      <img class="h-16 m-auto" data-test-qualification="BAD" alt="" role="presentation" src="/assets/images/match-qualification-bad.svg" />
  
      <div class="my-1">
        <span class="font-semibold">You're not a Match.</span> Your {{theme 'documentType'}} isn't properly representing you. Consider updating your {{theme 'documentType'}}.
      </div>
    {{/if}}
  
    {{#if (eq @qualification 'OK')}}
      <img class="h-16 m-auto" data-test-qualification="OK" alt="" role="presentation" src="/assets/images/match-qualification-ok.svg" />
  
      <div class="font-semibold my-2">
        It seems you are an OK Match
      </div>
    {{/if}}
  
    {{#if (eq @qualification 'GOOD')}}
      <img class="h-16 m-auto" data-test-qualification="GOOD" alt="" role="presentation" src="/assets/images/match-qualification-good.svg" />
  
      <div class="font-semibold my-1">
        You're a Good Match!
      </div>
    {{/if}}
  
    {{#if (eq @qualification 'GREAT')}}
      <img class="h-16 m-auto" data-test-qualification="GREAT" alt="" role="presentation" src="/assets/images/match-qualification-great.svg" />
  
      <div class="text-center font-semibold my-1">
        You're a top match!
      </div>
    {{/if}}
  </div>
  
  */
  {
    "id": "+bCKVrq8",
    "block": "[[[10,0],[14,0,\"text-sm text-neutral-900\"],[12],[1,\"\\n\"],[41,[28,[37,1],[[30,1],\"BAD\"],null],[[[1,\"    \"],[10,\"img\"],[14,0,\"h-16 m-auto\"],[14,\"alt\",\"\"],[14,\"role\",\"presentation\"],[14,\"src\",\"/assets/images/match-qualification-bad.svg\"],[12],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"my-1\"],[12],[1,\"\\n      \"],[10,1],[14,0,\"font-semibold\"],[12],[1,\"You're not a Match.\"],[13],[1,\" Your \"],[1,[28,[35,2],[\"documentType\"],null]],[1,\" isn't properly representing you. Consider updating your \"],[1,[28,[35,2],[\"documentType\"],null]],[1,\".\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[28,[37,1],[[30,1],\"OK\"],null],[[[1,\"    \"],[10,\"img\"],[14,0,\"h-16 m-auto\"],[14,\"alt\",\"\"],[14,\"role\",\"presentation\"],[14,\"src\",\"/assets/images/match-qualification-ok.svg\"],[12],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"font-semibold my-2\"],[12],[1,\"\\n      It seems you are an OK Match\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[28,[37,1],[[30,1],\"GOOD\"],null],[[[1,\"    \"],[10,\"img\"],[14,0,\"h-16 m-auto\"],[14,\"alt\",\"\"],[14,\"role\",\"presentation\"],[14,\"src\",\"/assets/images/match-qualification-good.svg\"],[12],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"font-semibold my-1\"],[12],[1,\"\\n      You're a Good Match!\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[28,[37,1],[[30,1],\"GREAT\"],null],[[[1,\"    \"],[10,\"img\"],[14,0,\"h-16 m-auto\"],[14,\"alt\",\"\"],[14,\"role\",\"presentation\"],[14,\"src\",\"/assets/images/match-qualification-great.svg\"],[12],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"text-center font-semibold my-1\"],[12],[1,\"\\n      You're a top match!\\n    \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"@qualification\"],false,[\"if\",\"eq\",\"theme\"]]",
    "moduleName": "widget/components/match/fit-title.hbs",
    "isStrictMode": false
  });
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
  _exports.default = _default;
});