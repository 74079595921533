define("widget/initializers/sentry", ["exports", "@sentry/browser", "@sentry/integrations", "widget/config/environment"], function (_exports, Sentry, Integrations, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  var ignoreErrorPatterns = [
  // from: https://gist.github.com/pioug/b006c983538538066ea871d299d8e8bc
  /^No error$/, /__show__deepen/, /Access is denied/, /anonymous function: captureException/, /Blocked a frame with origin/, /console is not defined/, /DataCloneError/, /feedConf/, /MyIPhoneApp/, /snapchat.com/, /vid_mate_check is not defined/, /win\.document\.body/, /window\._sharedData\.entry_data/, /ztePageScrollModule/,
  // Custom
  /Ember Data Request.+returned a 404/, /_avast_submit/];
  var ignoreErrorNames = ['TransitionAborted',
  // aborted route transitions
  'AbortError' // aborted route transitions
  ];

  function shouldReport(error) {
    var matchesPattern = ignoreErrorPatterns.some(function (pattern) {
      return error && error.message && error.message.match(pattern);
    });
    if (matchesPattern) {
      return false;
    }
    var matchesName = ignoreErrorNames.some(function (name) {
      return error && error.name === name;
    });
    if (matchesName) {
      return false;
    }
    return true;
  }
  function initialize() {
    if (_environment.default.environment !== 'production') {
      return;
    }
    Sentry.init({
      dsn: _environment.default.SENTRY_KEY,
      integrations: [new Integrations.Ember()],
      release: _environment.default.VERCEL_GIT_COMMIT_SHA,
      beforeSend: function beforeSend(event, hint) {
        var error = hint.originalException;
        if (shouldReport(error)) {
          return event;
        }
      }
    });
  }
  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});