define("widget/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "NaLI+TWj",
    "block": "[[[10,0],[15,0,[29,[\"w-full h-full \",[52,[30,0,[\"isMobile\"]],\"animate-slide-up\"]]]],[12],[1,\"\\n  \"],[46,[28,[37,2],null,null],null,null,null],[1,\"\\n\"],[13]],[],false,[\"if\",\"component\",\"-outlet\"]]",
    "moduleName": "widget/templates/application.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});