define("widget/templates/job/match/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "s0UV+MSs",
    "block": "[[[1,\"\\n\"],[41,[30,0,[\"parserCondition\"]],[[[1,\"  \"],[10,\"img\"],[14,0,\"m-auto\"],[14,\"src\",\"/assets/images/parse_error.svg\"],[14,\"role\",\"presentation\"],[14,\"alt\",\"\"],[12],[13],[1,\"\\n  \\n  \"],[10,0],[14,0,\"my-8 text-center\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"font-semibold mb-2 text-red-600\"],[12],[1,\"\\n      Couldn't read your resume\\n    \"],[13],[1,\"\\n    \"],[10,2],[14,0,\"mb-2 text-sm\"],[12],[1,\"\\n      Make sure you uploaded a PDF or Word doc to see how it matches with this role. This tool works with resumes written in english only (for now).\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,\"img\"],[14,0,\"m-auto\"],[14,\"src\",\"/assets/images/error.png\"],[14,\"role\",\"presentation\"],[14,\"alt\",\"\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"my-8 text-center\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"font-semibold text-base mb-2 text-red-600\"],[12],[1,\"\\n      Oops! Something Went Wrong\\n    \"],[13],[1,\"\\n\\n    \"],[10,2],[14,0,\"mb-2 text-sm\"],[12],[1,\"\\n\"],[41,[30,0,[\"reasonText\"]],[[[1,\"        \"],[1,[30,0,[\"reasonText\"]]],[1,\"\\n\"]],[]],[[[1,\"        We had an issue with our system while reviewing your \"],[1,[28,[35,1],[\"documentType\"],null]],[1,\" for match feedback. Please try again soon. If this happens\\n        to you more than once, please contact us at \"],[10,3],[14,0,\"text-blue-500 font-semibold\"],[14,6,\"support@topresume.com\"],[12],[1,\"support@topresume.com\"],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]],[1,\"\\n\"],[41,[30,0,[\"allowUpload\"]],[[[1,\"  \"],[8,[39,2],[[24,0,\"block text-center w-full bg-blue-500 text-white rounded py-4 text-sm mb-4 disabled:opacity-50\"],[16,5,[29,[\"background-color: \",[28,[37,1],[\"primaryColor\"],null],\";\"]]]],[[\"@route\"],[\"job.match.upload\"]],[[\"default\"],[[[[1,\"\\n  Back To Upload\\n  \"]],[]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"theme\",\"link-to\"]]",
    "moduleName": "widget/templates/job/match/error.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});