define("widget/templates/job/match/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "hQaWZpU3",
    "block": "[[[41,[30,0,[\"uploadAnimation\",\"isRunning\"]],[[[1,\"  \"],[10,0],[14,0,\"mx-auto my-auto\"],[12],[1,\"\\n    \"],[8,[39,1],null,[[\"@size\",\"@percent\"],[300,[30,0,[\"uploadPercent\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,2],null,[[\"@onCreateMatch\",\"@job\"],[[30,0,[\"createMatch\"]],[30,0,[\"job\"]]]],null],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"progress-ring\",\"create-next-match-from\"]]",
    "moduleName": "widget/templates/job/match/create.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});