define("widget/helpers/strip-html", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.stripHtml = stripHtml;
  var div = document.createElement("div");
  var _default = Ember.Helper.helper(function stripHtmlHelper(params /*, hash*/) {
    var html = params[0];
    return stripHtml(html);
  });
  _exports.default = _default;
  function stripHtml(html) {
    if (!html) {
      return '';
    }
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  }
});