define("widget/templates/job/match/no-resume", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "mvKZSVuM",
    "block": "[[[10,\"img\"],[14,0,\"m-auto\"],[14,\"alt\",\"\"],[14,\"role\",\"presentation\"],[14,\"src\",\"/assets/images/resume-samples.png\"],[12],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"text-center\"],[12],[1,\"\\n  \"],[10,1],[14,0,\"text-xs text-neutral-500 inline-block\"],[12],[1,\"Powered by \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"pb-4 inline-block\"],[12],[1,\"\\n    \"],[10,\"img\"],[14,0,\"inline-block h-6\"],[14,\"alt\",\"\"],[14,\"role\",\"presentation\"],[14,\"src\",\"/assets/images/resumeio-logo.svg\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"font-semibold mb-2\"],[12],[1,\"\\n    Pick from one of hundreds of professional templates\\n    with this free \"],[1,[28,[35,0],[\"documentType\"],null]],[1,\" builder.\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"text-sm text-neutral-900 mb-6\"],[12],[1,\"\\n    Save time with our easy \"],[1,[28,[35,0],[\"documentType\"],null]],[1,\" builder.\\n    Rapidly make a perfect \"],[1,[28,[35,0],[\"documentType\"],null]],[1,\" employers love.\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"mb-4\"],[12],[1,\"\\n\"],[1,\"  \"],[11,3],[24,6,\"https://resume.io?ref=5zsuw9\"],[24,\"target\",\"_blank\"],[24,\"referrerpolicy\",\"no-referrer-when-downgrade\"],[24,\"rel\",\"noopener noreferrer\"],[24,0,\"block text-center w-full bg-blue-500 text-white rounded py-4 text-sm mb-4 disabled:opacity-50\"],[16,5,[29,[\"background-color: \",[28,[37,0],[\"primaryColor\"],null],\";\"]]],[4,[38,1],null,null],[12],[1,\"\\n    Build Resume\\n  \"],[13],[1,\"\\n\\n\"],[1,\"  \"],[8,[39,2],[[24,0,\"w-full block text-center text-sm\"],[16,5,[29,[\"color: \",[28,[37,0],[\"primaryColor\"],null],\";\"]]]],[[\"@route\"],[\"job.match.upload\"]],[[\"default\"],[[[[1,\"\\n    Back to upload screen\\n  \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"theme\",\"track\",\"link-to\"]]",
    "moduleName": "widget/templates/job/match/no-resume.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});