define("widget/tailwind/config", [], function () {
  "use strict";

  module.exports = {
    purge: {},
    darkMode: false,
    // or 'media' or 'class'
    theme: {
      colors: {
        white: '#fff',
        neutral: {
          50: '#F7F9FC',
          100: '#EFF2F9',
          150: '#E7EAF4',
          200: '#D9DEEB',
          400: '#9FA6BB',
          500: '#828BA2',
          900: '#1E2532',
          950: '#0F141E'
        },
        blue: {
          100: '#EAF6FF',
          200: '#EAF6FF',
          500: '#1A91F0',
          600: '#1170CD',
          700: '#10529B'
        },
        indigo: {
          100: '#F1F2FF',
          400: '##9BA1FB',
          500: '#7A82F5',
          600: '#5660E8',
          700: '#10529B',
          800: '#282B8F',
          900: '#1A1C6A'
        },
        green: {
          100: '#E7F4ED',
          400: '#48BA75',
          500: '#339D5D',
          600: '#217D47',
          700: '#135C37'
        },
        amber: {
          100: '#FFF2CC',
          300: '#F9BA44',
          400: '#EC930C',
          500: '#CF760D',
          600: '#A85A0E'
        },
        orange: {
          100: '#FEEBE3',
          400: '#F68559',
          500: '#EE571D',
          600: '#BF4213'
        },
        red: {
          100: '#FFEAEC',
          500: '#FB4458',
          600: '#DA0C22',
          700: '#A10E1D'
        },
        stone: {
          100: '#F3F1EB',
          600: '#776D59'
        }
      },
      fontSize: {
        xxs: ['0.6875rem', '0.875rem'],
        // ???
        xs: ['0.8125rem', '1rem'],
        // "Tiny"
        sm: ['0.875rem', '1.25rem'],
        // "Caption"
        base: ['1.0625rem', '1.5rem'],
        // "Body"
        lg: ['1.5rem', '2rem'] // "Subhead"
      },

      fontWeight: {
        normal: '400',
        // "Regular"
        medium: '500',
        // "Medium"
        semibold: '600' // "SemiBold"
      },

      fontFamily: {
        sans: ["SF Pro Display", "Helvetica Neue", "ui-sans-serif", "system-ui", "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Arial", "Noto Sans", "sans-serif", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"]
      },
      boxShadow: {
        sm: '0px 2px 4px rgba(15, 56, 113, 0.12), 0px 0px 1px rgba(12, 38, 76, 0.24)',
        md: '0px 4px 12px -4px rgba(15, 56, 113, 0.08), 0px 8px 24px -4px rgba(15, 56, 113, 0.08), 0px 0px 1px rgba(12, 38, 76, 0.32)',
        lg: '0px 16px 48px -8px rgba(15, 56, 113, 0.08), 0px 0px 1px rgba(12, 38, 76, 0.4)',
        xl: '0px 2px 4px -1px rgba(15, 56, 113, 0.08), 0px 8px 20px -4px rgba(15, 56, 113, 0.12)'
      },
      extend: {
        keyframes: {
          slideUp: {
            '0%': {
              transform: 'translateY(100%)'
            },
            '100%': {
              transform: 'translateY(0%)'
            }
          }
        },
        animation: {
          'slide-up': 'slideUp 0.4s'
        },
        strokeWidth: {
          '3': '3px',
          '4': '4px'
        }
      }
    },
    variants: {
      extend: {
        opacity: ['disabled']
      }
    },
    plugins: [require("@tailwindcss/forms")]
  };
});