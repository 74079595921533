define("widget/templates/job/match/report/terms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "b2uW7gpM",
    "block": "[[[41,[30,0,[\"theme\",\"shouldUseSimpleTerms\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@acceptTermsOfService\",\"@job\"],[[30,0,[\"acceptTermsOfService\"]],[30,1]]],null],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,2],null,[[\"@acceptTermsOfService\",\"@job\"],[[30,0,[\"acceptTermsOfService\"]],[30,1]]],null],[1,\"\\n\"]],[]]]],[\"@model\"],false,[\"if\",\"terms-simple\",\"terms-split\"]]",
    "moduleName": "widget/templates/job/match/report/terms.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});